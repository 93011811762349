:root {
  --s-textcolor: #002E4B;
  --s-linkcolor: #002E4B;
  --s-linkcolor-hov: #E54C58;
  --s-togglecolor: #002E4B;
  --s-themecolor: #002E4B;
  --s-secondarycolor: #D6D0C4;
  --s-navi-bg: #EDEAE4;
  --s-iconcolor: #002E4B;
  --s-iconcolor-hov: #E54C58;
  --s-btn-bg: #002E4B;
  --s-btn-bg-hov: transparent;
  --s-btn-color: #fff;
  --s-btn-color-hov: #002E4B;
  --s-btn-border: #002E4B;
  --s-logoWhiteRed: url(/images/logo-hostellerie-horizontal-weiss-rot.svg);
  --s-headerHeight: 178px;
}
@media (max-width: 1023px) {
  :root {
    --s-headerHeight: 114px;
  }
}
:root {
  --deskWidth: 1616px;
  --deskSpace: 40px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 20px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 15px;
  }
}
:root {
  --spaceUnit: 35px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 25px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .button {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.button-arrow {
  display: inline-block;
  font-family: 'QuicheSansMedium', serif;
  font-size: 24px;
  line-height: 1.25;
  hyphens: auto;
  cursor: pointer;
  color: var(--s-textcolor);
  padding: 15px 0 15px 98px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  border: none;
  transition: all 0.4s;
  position: relative;
}
.button-arrow:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: 17px;
  top: 0;
  background-color: var(--s-themecolor);
  border-radius: 1000px;
  transition: all 0.4s;
}
.button-arrow:after {
  content: '';
  width: 56px;
  height: 28px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 16px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: var(--s-textcolor);
  transition: all 0.4s;
}
.button-arrow:hover,
.button-arrow:focus {
  transform: translateX(40px);
}
.button-arrow:hover:before,
.button-arrow:focus:before {
  opacity: 0.3;
}
@media (max-width: 1023px) {
  .button-arrow {
    font-size: 19px;
    line-height: 1.26315789;
    padding: 8px 0 8px 64px;
  }
  .button-arrow:before {
    width: 40px;
    height: 40px;
    left: 12px;
  }
  .button-arrow:after {
    width: 38px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 10px;
  }
  .button-arrow:hover,
  .button-arrow:focus {
    transform: translateX(22px);
  }
}
@font-face {
  font-family: "QuicheSansMedium";
  src: url('/extras/fonts/QuicheSansMedium/font.woff2') format('woff2'), url('/extras/fonts/QuicheSansMedium/font.woff') format('woff');
}
@font-face {
  font-family: 'Basier Square Regular';
  src: url('/extras/fonts/basier-square/basiersquare-regular-webfont.woff2') format('woff2'), url('/extras/fonts/basier-square/basiersquare-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Square Regular Italic';
  src: url('/extras/fonts/basier-square/basiersquare-regularitalic-webfont.woff2') format('woff2'), url('extras/fonts/basier-square/basiersquare-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Basier Square Semibold';
  src: url('/extras/fonts/basier-square/basiersquare-semibold-webfont.woff2') format('woff2'), url('extras/fonts/basier-square/basiersquare-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Square Semibold Italic';
  src: url('/extras/fonts/basier-square/basiersquare-semibolditalic-webfont.woff2') format('woff2'), url('extras/fonts/basier-square/basiersquare-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
}
*::selection {
  background: #002E4B;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 21px;
  font-family: 'Basier Square Regular', sans-serif;
  line-height: 1.47619048;
}
@media (max-width: 767px) {
  input,
  textarea {
    font-size: 17px;
    line-height: 1.58823529;
  }
}
.unit caption {
  display: none;
}
.flag {
  background: #002E4B;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  position: relative;
}
.area .unit {
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  position: relative;
}
.unit .part {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 7.5px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 16px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.layout1 #expo:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  pointer-events: none;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 68px;
  height: 68px;
  transform: translateY(-50%);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  #expo a.link {
    width: 21px;
    height: 21px;
  }
}
#expo a.link.prev {
  left: 24px;
  background-image: url(/images/arrow-left-white.svg);
}
@media (max-width: 767px) {
  #expo a.link.prev {
    left: 20px;
  }
}
#expo a.link.next {
  right: 24px;
  background-image: url(/images/arrow-right-white.svg);
}
@media (max-width: 767px) {
  #expo a.link.next {
    right: 20px;
  }
}
table.link,
table.link tbody {
  float: left;
  display: none;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #002E4B;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Basier Square Regular', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #002E4B;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #002E4B;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Basier Square Regular', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 70px;
    padding-top: 19.5px;
    padding-bottom: 19.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  min-height: 70px;
  padding: 20px 18px 24px;
  appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form input.text::placeholder,
.unit.form textarea::placeholder {
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .unit.form input.text,
  .unit.form textarea {
    padding: 10px;
    min-height: 35px;
  }
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 19.5px;
    padding-bottom: 19.5px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 31px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 19.5px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Basier Square Regular', sans-serif;
  font-size: 21px;
  line-height: 1.47619048;
  background: #fff;
  height: 70px;
  border: 1px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop span::file-selector-button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
  margin-right: 16px;
}
.unit.form .ship div.chop span::file-selector-button:hover,
.unit.form .ship div.chop span::file-selector-button:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .unit.form .ship div.chop span::file-selector-button {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: left;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .unit.form .submit {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #D6D0C4;
  border-bottom: 1px solid #D6D0C4;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: var(--spacePart) 0;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
}
.table td {
  padding: var(--spacePart) 0;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
.home {
  float: left;
  position: relative;
}
.logo {
  width: auto;
  height: 100%;
}
.logo {
  width: auto;
  height: 100%;
  transition: all 0.4s;
}
.logo--whiteRed {
  display: none;
}
.layout1 .logo--whiteRed,
.pageColor--blue .logo--whiteRed {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 1;
}
.layout1 .logo--blueRed,
.pageColor--blue .logo--blueRed {
  opacity: 0;
}
body.cb-toggle-target-active .logo--whiteRed {
  opacity: 0;
}
body.cb-toggle-target-active .logo--blueRed {
  opacity: 1;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Basier Square Regular', sans-serif;
  font-size: 21px;
  line-height: 1.47619048;
  color: var(--s-textcolor);
  accent-color: #002E4B;
  overflow: hidden;
  hyphens: none;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  padding-top: var(--s-headerHeight);
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 17px;
    line-height: 1.58823529;
  }
}
main {
  float: left;
  width: 100%;
  flex: 1 0;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  --deskWidth: 1800px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: all 0.4s;
  background-color: var(--s-themecolor);
}
.layout1 .section--header {
  --s-linkcolor: #fff;
  --s-textcolor: #fff;
  --s-togglecolor: #fff;
  --s-btn-bg: #fff;
  --s-btn-color: #002E4B;
  --s-btn-color-hov: #fff;
  --s-iconcolor: #fff;
}
body.cb-toggle-target-active .section--header {
  background-color: var(--s-navi-bg);
  --s-linkcolor: #002E4B;
  --s-togglecolor: #002E4B;
  --s-btn-bg: #002E4B;
  --s-btn-color: #fff;
  --s-btn-color-hov: #002E4B;
  --s-iconcolor: #002E4B;
}
.container--head {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.headpart {
  float: left;
  display: flex;
  flex-direction: column;
}
.langnavi--header {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.langnavi--header .sub1 {
  display: flex;
  gap: 10px;
}
.langnavi--header .sub1 > .item > .menu {
  font-size: 14px;
  line-height: 1.21428571;
  text-decoration: none;
  transition: all 0.4s;
}
.langnavi--header .sub1 > .item > .menu.path {
  text-decoration: underline;
}
.serviceLinks--desktop {
  display: none;
}
.cbdModule--serviceLink1,
.cbdModule--serviceLink2 {
  width: auto;
}
.cbdModule--serviceLink1 .open,
.cbdModule--serviceLink2 .open {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
  font-size: 14px;
  line-height: 1.21428571;
  padding: 9px 23px 7px;
  min-width: unset;
}
.cbdModule--serviceLink1 .open:hover,
.cbdModule--serviceLink2 .open:hover,
.cbdModule--serviceLink1 .open:focus,
.cbdModule--serviceLink2 .open:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .cbdModule--serviceLink1 .open,
  .cbdModule--serviceLink2 .open {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
@media (max-width: 1200px) {
  .cbdModule--serviceLink1 .open,
  .cbdModule--serviceLink2 .open {
    padding: 9px 10px 7px;
  }
}
.cbdModule--serviceLink1 .open {
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #002E4B;
  --s-btn-color: #002E4B;
  --s-btn-color-hov: #fff;
}
body:not(.cb-toggle-target-active).layout1 .serviceLinks--desktop .cbdModule--serviceLink1 .open,
body:not(.cb-toggle-target-active).pageColor--blue .serviceLinks--desktop .cbdModule--serviceLink1 .open {
  --s-btn-border: #fff;
  --s-btn-color: #fff;
  --s-btn-bg-hov: #fff;
  --s-btn-color-hov: #002E4B;
}
body:not(.cb-toggle-target-active).layout1 .serviceLinks--desktop .cbdModule--serviceLink2 .open,
body:not(.cb-toggle-target-active).pageColor--blue .serviceLinks--desktop .cbdModule--serviceLink2 .open {
  --s-btn-border: #fff;
  --s-btn-bg: #fff;
  --s-btn-color: #002E4B;
  --s-btn-bg-hov: #002E4B;
  --s-btn-color-hov: #fff;
}
.part--serviceIconLinks {
  width: auto;
}
.part--serviceIconLinks .iconLinks__content {
  float: left;
  width: 100%;
  display: flex;
  gap: 13px;
}
.part--serviceIconLinks .iconLinks__icon {
  float: left;
  width: 24px;
  height: 24px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: var(--s-iconcolor);
  transition: all 0.4s;
}
.part--serviceIconLinks .iconLinks__icon--voucher {
  mask-image: url(/images/icon-gift.svg);
}
.part--serviceIconLinks .iconLinks__icon--webcam {
  mask-image: url(/images/icon-webcam.svg);
}
.part--serviceIconLinks .iconLinks__icon:hover,
.part--serviceIconLinks .iconLinks__icon:focus {
  background-color: var(--s-iconcolor-hov);
}
.pageColor--blue {
  --s-themecolor: #002E4B;
  --s-togglecolor: #fff;
}
.pageColor--blue .link--arrow,
.pageColor--blue .fold-toggle {
  --s-themecolor: #E54C58;
}
.pageColor--blue .section--multimood {
  --s-textcolor: #fff;
  --s-linkcolor: #fff;
}
.pageColor--blue .area--one .unitFour {
  --s-textcolor: #fff;
  --s-linkcolor: #fff;
  --s-btn-bg: #fff;
  --s-btn-color: #002E4B;
  --s-btn-color-hov: #fff;
  --s-btn-border: #fff;
}
.pageColor--brown {
  --s-themecolor: #958E7F;
}
.pageColor--brown .area--one .unitThree .link--arrow,
.pageColor--brown .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.pageColor--beige {
  --s-themecolor: #D6D0C4;
}
.pageColor--beige .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.pageColor--offwhite {
  --s-themecolor: #EDEAE4;
}
.pageColor--offwhite .link--arrow,
.pageColor--offwhite .fold-toggle {
  --s-themecolor: #D6D0C4;
}
.pageColor--offwhite .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.pageColor--green {
  --s-themecolor: #DCE3CF;
}
.pageColor--green .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.pageColor--rose {
  --s-themecolor: #F7DACF;
}
.pageColor--rose .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.pageColor--lightblue {
  --s-themecolor: #D9E1E8;
}
.pageColor--lightblue .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.secondaryColor--offwhite {
  --s-secondarycolor: #EDEAE4;
}
.secondaryColor--offwhite-light {
  --s-secondarycolor: #f6f5f2;
}
.layout1 .section--one .area--one .unitFour {
  --s-textcolor: #fff;
  --s-linkcolor: #fff;
  --s-btn-bg: #fff;
  --s-btn-color: #002E4B;
  --s-btn-color-hov: #fff;
  --s-btn-border: #fff;
}
.layout1 .section--one .area--one .unitFour .link--arrow {
  --s-themecolor: #E54C58;
}
.section--two {
  --s-themecolor: #DCE3CF;
}
.section--two .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.section--three {
  --s-themecolor: #DCE3CF;
  background-color: #EDEAE4;
}
.section--three .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.section--four {
  --s-themecolor: #F7DACF;
}
.section--four .area--one .unitFour .link--arrow {
  --s-themecolor: #fff;
}
.section--five {
  background-color: #002E4B;
  --s-themecolor: #002E4B;
  --s-textcolor: #EDEAE4;
  --s-linkcolor: #EDEAE4;
}
.section--five .link--arrow,
.section--five .fold-toggle {
  --s-themecolor: #E54C58;
}
.section--five .area--one .unitThree {
  --s-textcolor: #002E4B;
  --s-linkcolor: #002E4B;
}
.section--five .area--one .unitFour {
  --s-textcolor: #EDEAE4;
  --s-linkcolor: #EDEAE4;
  --s-btn-bg: #EDEAE4;
  --s-btn-color: #002E4B;
  --s-btn-color-hov: #EDEAE4;
  --s-btn-border: #EDEAE4;
}
.section--six {
  --s-themecolor: #958E7F;
}
.section--six .area--one .unitThree,
.section--six .area--one .unitFour {
  background-color: #958E7F;
  --s-textcolor: #fff;
  --s-linkcolor: #fff;
}
.section--six .area--one .unitThree .link--arrow,
.section--six .area--one .unitFour .link--arrow {
  --s-themecolor: #D6D0C4;
}
.section--seven {
  background-color: #D9E1E8;
  --s-themecolor: #D9E1E8;
}
.section--seven .link--arrow,
.section--seven .fold-toggle {
  --s-themecolor: #fff;
}
.section--eight .link--arrow,
.section--eight .fold-toggle {
  --s-themecolor: #fff;
}
.section--nine {
  background-color: var(--s-secondarycolor);
}
#view.area1--empty .section--area1,
#view.area2--empty .section--area2,
#view.area3--empty .section--area3,
#view.area4--empty .section--area4,
#view.area5--empty .section--area5,
#view.area6--empty .section--area6,
#view.area7--empty .section--area7,
#view.area8--empty .section--area8-9 .eventpart--2,
#view.area9--empty .section--area8-9 .eventpart--3 {
  display: none;
}
@media (max-width: 1023px) {
  .layout1 .section--multimood .desk,
  .layout2 .section--multimood .desk {
    --deskWidth: 100vw;
    --deskMaxWidth: 100vw;
  }
}
.container--events {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.eventpart {
  float: left;
  box-sizing: border-box;
}
.section--newsletter {
  background-color: #D6D0C4;
}
.section--newsletter .desk {
  --deskWidth: 970px;
  --deskSpace: 40px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
.container--newsletter {
  float: left;
  width: 100%;
  display: flex;
}
.newsletter__content {
  float: left;
}
.newsletter__link {
  float: left;
}
.cbdModule--newsletterLink {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cbdModule--newsletterLink .open {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.cbdModule--newsletterLink .open:hover,
.cbdModule--newsletterLink .open:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .cbdModule--newsletterLink .open {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.serviceLinks--mobile {
  float: left;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2001;
  display: flex;
}
.serviceLinks--mobile .cbdModule {
  width: 50%;
}
.serviceLinks--mobile .cbdModule.cbdModule--serviceLink1 .open {
  --s-btn-bg: #fff;
}
.serviceLinks--mobile .cbdModule.cbdModule--serviceLink2 .open {
  --s-btn-bg-hov: #fff;
}
.serviceLinks--mobile .cbdModule .open {
  width: 100%;
}
.container--mood {
  float: left;
  width: 100%;
}
.layout3 .container--mood {
  display: flex;
}
.layout4 .container--mood {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mood {
  float: left;
  z-index: 2;
  box-sizing: border-box;
}
.content {
  float: left;
  width: 100%;
  z-index: 3;
  box-sizing: border-box;
}
.layout1 .content {
  --s-textcolor: #fff;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.layout2 .content {
  display: flex;
  gap: 40px;
}
.moodbackground {
  float: left;
  width: 100%;
  background-color: var(--s-themecolor);
  position: absolute;
  left: 0;
  top: 0;
}
#edit .unit--facilities {
  min-height: 70px;
}
.unit--facilities .part {
  padding-top: var(--spacePart);
  padding-bottom: var(--spacePart);
  border-top: 1px solid var(--s-textcolor);
  border-bottom: 1px solid var(--s-textcolor);
}
.unit--facilities .part + .part {
  margin-top: calc((var(--spacePart) + 1px) * -1);
}
.cbdModule--introText {
  margin-top: 50px;
}
.cbdModule--voucherCta {
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.cbdModule--voucherCta .open {
  float: left;
  box-sizing: border-box;
  border-radius: 10000px;
  font-family: 'QuicheSansMedium', serif;
  background-color: #D6D0C4;
  text-decoration: none;
  text-align: center;
  position: relative;
  transform: rotate(-15deg);
  transition: all 0.4s;
  color: var(--s-textcolor);
}
.cbdModule--voucherCta .open:before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-gift.svg);
  background-color: #002E4B;
}
.cbdModule--voucherCta .open:hover,
.cbdModule--voucherCta .open:focus {
  transform: rotate(-15deg) scale(1.1);
}
.footarea {
  float: left;
  width: 100%;
}
.footunit {
  float: left;
  width: 100%;
  display: flex;
}
.footunit--two {
  display: flex;
  justify-content: flex-end;
}
.footpart {
  float: left;
  display: flex;
  flex-direction: column;
}
.footlogo {
  float: left;
  width: auto;
}
.vcard {
  font-size: 16px;
  line-height: 1.75;
}
.part--socialLinks .iconLinks__content {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.part--socialLinks .iconLinks__icon {
  float: left;
  width: 26px;
  height: 26px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #002E4B;
  transition: all 0.4s;
}
.part--socialLinks .iconLinks__icon--facebook {
  mask-image: url(/images/icon-facebook.svg);
}
.part--socialLinks .iconLinks__icon--instagram {
  mask-image: url(/images/icon-instagram.svg);
}
.part--socialLinks .iconLinks__icon--youtube {
  width: 32px;
  mask-image: url(/images/icon-youtube.svg);
}
.part--socialLinks .iconLinks__icon:hover,
.part--socialLinks .iconLinks__icon:focus {
  background-color: #E54C58;
}
.container--reviews {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.container--reviews * {
  width: unset;
  max-width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
.container--reviews .eNnbgE {
  display: block !important;
}
@media (max-width: 1023px) {
  .container--reviews {
    align-items: center;
  }
}
.ca-widget-container {
  float: left;
  width: 115px;
  height: 210px;
  position: relative;
}
@media (max-width: 768px) {
  .ca-widget-container {
    width: 96px;
    height: 96px;
  }
}
.unit--quicklinks {
  --spacePart: 0;
}
#edit .unit--quicklinks {
  min-height: 20px;
}
.unit--quicklinks .unit__body {
  display: flex;
  flex-direction: column;
}
.unit--quicklinks .part .open {
  font-size: 16px;
  line-height: 1.75;
}
@media (max-width: 1023px) {
  .unit--quicklinks .part .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.unit--legal {
  --spacePart: 0;
}
#edit .unit--legal {
  min-height: 17px;
}
.unit--legal .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}
.unit--legal .part {
  width: auto;
}
.unit--legal .part .open {
  font-size: 14px;
  line-height: 1.28571429;
}
.langnavi--footer {
  float: left;
  width: 100%;
}
.langnavi--footer .sub1 {
  display: flex;
  gap: 10px;
}
.langnavi--footer .sub1 > .item > .menu {
  font-size: 14px;
  line-height: 1.21428571;
  text-decoration: none;
  transition: all 0.4s;
}
.langnavi--footer .sub1 > .item > .menu:hover,
.langnavi--footer .sub1 > .item > .menu:focus {
  text-decoration: underline;
}
.langnavi--footer .sub1 > .item > .menu.path {
  text-decoration: underline;
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100vw - 80px);
  width: 400px;
  z-index: 2100;
  font-family: 'Basier Square Regular', sans-serif;
  font-size: 15px;
  line-height: 1.46666667;
  color: var(--s-textcolor);
  background-color: #fff;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1023px) {
  #cb-cookie-warning {
    left: 24px;
    bottom: 24px;
    max-width: calc(100vw - 48px);
  }
}
@media (max-width: 1023px) {
  #cb-cookie-warning {
    left: 16px;
    bottom: 16px;
    max-width: calc(100vw - 32px);
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    padding: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
}
.cb-cookie-warning__button {
  float: left;
  width: 50%;
  cursor: pointer;
}
#cb-cookie-warning__button--decline {
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  color: var(--s-linkcolor);
}
#cb-cookie-warning__button--decline:hover,
#cb-cookie-warning__button--decline:focus {
  color: var(--s-linkcolor-hov);
}
@media (max-width: 1023px) {
  #cb-cookie-warning__button--decline {
    font-size: 14px;
    line-height: 1.21428571;
  }
}
#cb-cookie-warning__button--accept {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  #cb-cookie-warning__button--accept {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
a {
  color: var(--s-linkcolor);
  text-decoration: underline;
}
a:hover,
a:focus {
  color: var(--s-linkcolor-hov);
  text-decoration: none;
}
h1 {
  font-size: clamp(2.125rem, 3.75vw, 3.75rem);
  line-height: 1.23333333;
  font-family: 'QuicheSansMedium', serif;
  color: var(--s-textcolor);
  font-weight: 400;
}
h1 strong {
  font-family: 'QuicheSansMedium', serif;
}
@media (max-width: 1023px) {
  h1 {
    line-height: 1.23529412;
  }
}
h2 {
  font-size: clamp(1.688rem, 2.875vw, 2.875rem);
  line-height: 1.2173913;
  font-family: 'QuicheSansMedium', serif;
  color: var(--s-textcolor);
  font-weight: 400;
}
h2 strong {
  font-family: 'QuicheSansMedium', serif;
}
@media (max-width: 1023px) {
  h2 {
    line-height: 1.2962963;
  }
}
h3 {
  font-size: clamp(1.313rem, 2.125vw, 2.125rem);
  line-height: 1.64705882;
  font-family: 'QuicheSansMedium', serif;
  color: var(--s-textcolor);
  font-weight: 400;
  line-height: 1.42857143;
}
h3 strong {
  font-family: 'QuicheSansMedium', serif;
}
h4 {
  font-size: 24px;
  line-height: 1.25;
  font-family: 'QuicheSansMedium', serif;
  color: var(--s-textcolor);
  font-weight: 400;
}
h4 strong {
  font-family: 'QuicheSansMedium', serif;
}
@media (max-width: 1023px) {
  h4 {
    font-size: 19px;
    line-height: 1.26315789;
  }
}
.norm {
  color: var(--s-textcolor);
}
.norm i {
  font-family: 'Basier Square Regular Italic', serif;
}
.norm strong i {
  font-family: 'Basier Square Semibold Italic', sans-serif;
}
.loud {
  font-size: 24px;
  line-height: 1.29166667;
  color: var(--s-textcolor);
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
}
.loud i {
  font-family: 'Basier Square Semibold Italic', sans-serif;
}
@media (max-width: 1023px) {
  .loud {
    font-size: 18px;
    line-height: 1.55555556;
  }
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--one .unitOne--textCenter {
  text-align: center;
}
.area--one .unitOne--1-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unitOne--1-1 .unit__content {
  max-width: 1064px;
}
.area--one .unitTwo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--one .unitThree {
  display: flex;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  background-color: #EDEAE4;
}
.area--one .unitThree .unit__content {
  box-sizing: border-box;
}
.area--one .unitFour {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: flex;
  background-color: var(--s-themecolor);
}
.area--one .unitFive {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unitFive .unit__content {
  max-width: 1064px;
}
.area--one .unitFive .part {
  padding-top: var(--spacePart);
  padding-bottom: var(--spacePart);
  border-top: 1px solid #D6D0C4;
  border-bottom: 1px solid #D6D0C4;
}
.area--one .unitSix {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-top: 2px solid var(--s-textcolor);
  border-bottom: 2px solid var(--s-textcolor);
  transition: all 0.4s;
}
.area--one .unitSix + .unitSix {
  margin-top: calc((var(--spaceTotal) + 2px) * -1);
}
.area--one .unitSix .unit__content {
  box-sizing: border-box;
}
.area--one .unitSix .more {
  display: block !important;
  overflow: hidden;
  transition: all 0.4s;
  box-sizing: border-box;
}
#view .area--one .unitSix .more {
  transform: translateY(-25px);
  opacity: 0;
  height: 0;
}
.area--one .unitSix .fold-toggle {
  float: left;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.4s;
}
.area--one .unitSix .fold-toggle:before {
  content: '';
  position: absolute;
  background-color: var(--s-themecolor);
  border-radius: 1000px;
  transition: all 0.4s;
}
.area--one .unitSix .fold-toggle:after {
  content: '';
  position: absolute;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-bottom.svg);
  background-color: var(--s-textcolor);
  transition: all 0.4s;
}
.area--one .unitSix .fold-toggle:hover:before,
.area--one .unitSix .fold-toggle:focus:before {
  opacity: 0.3;
}
.area--one .unitSix .unit__foot {
  display: none;
}
.area--one .unitSix.unit--foldOpen {
  background-color: rgba(237, 234, 228, 0.5);
}
#view .area--one .unitSix.unit--foldOpen .more {
  transform: translateY(0);
  opacity: 1;
  height: var(--js-elementHeight);
}
#view .area--one .unitSix.unit--foldOpen .fold-toggle:after {
  transform: scaleY(-100%);
}
.area--one .unit--form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unit--form .unit__content {
  box-sizing: border-box;
  max-width: 1064px;
  background-color: #f6f5f2;
}
.area--two .unitOne {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: flex;
  flex-direction: column;
  isolation: isolate;
  --s-textcolor: #fff;
  --s-linkcolor: #fff;
}
.area--two .unitOne .unit__content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.area--two .unitOne .unit__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.area--two .unitOne .unit__background * {
  height: 100%;
}
.area--two .unitOne .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unitOne .unit__background .placeholder {
  padding-bottom: 0;
}
.area--three .unitOne {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-top: 1px solid #D6D0C4;
  border-bottom: 1px solid #D6D0C4;
  --s-themecolor: #E54C58;
  box-sizing: border-box;
}
.area--three .unitOne + .unitOne {
  margin-top: calc((var(--spaceTotal) + 1px) * -1);
}
.area--three .unitOne.unit--isAnchor h3 {
  color: var(--s-linkcolor);
  transition: all 0.4s;
}
.area--three .unitOne.unit--isAnchor:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--s-themecolor);
  border-radius: 1000px;
  transition: all 0.4s;
}
.area--three .unitOne.unit--isAnchor:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: var(--s-textcolor);
  transition: all 0.4s;
}
.area--three .unitOne.unit--isAnchor:hover h3,
.area--three .unitOne.unit--isAnchor:focus h3 {
  color: var(--s-linkcolor-hov);
}
.area--three .unitOne.unit--isAnchor:hover:before,
.area--three .unitOne.unit--isAnchor:focus:before {
  opacity: 0.3;
}
.part.link--arrow .open {
  display: inline-block;
  font-family: 'QuicheSansMedium', serif;
  font-size: 24px;
  line-height: 1.25;
  hyphens: auto;
  cursor: pointer;
  color: var(--s-textcolor);
  padding: 15px 0 15px 98px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  border: none;
  transition: all 0.4s;
  position: relative;
}
.part.link--arrow .open:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: 17px;
  top: 0;
  background-color: var(--s-themecolor);
  border-radius: 1000px;
  transition: all 0.4s;
}
.part.link--arrow .open:after {
  content: '';
  width: 56px;
  height: 28px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 16px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: var(--s-textcolor);
  transition: all 0.4s;
}
.part.link--arrow .open:hover,
.part.link--arrow .open:focus {
  transform: translateX(40px);
}
.part.link--arrow .open:hover:before,
.part.link--arrow .open:focus:before {
  opacity: 0.3;
}
@media (max-width: 1023px) {
  .part.link--arrow .open {
    font-size: 19px;
    line-height: 1.26315789;
    padding: 8px 0 8px 64px;
  }
  .part.link--arrow .open:before {
    width: 40px;
    height: 40px;
    left: 12px;
  }
  .part.link--arrow .open:after {
    width: 38px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 10px;
  }
  .part.link--arrow .open:hover,
  .part.link--arrow .open:focus {
    transform: translateX(22px);
  }
}
.part.link--button .open {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.part.link--button .open:hover,
.part.link--button .open:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .part.link--button .open {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.togglenavigation {
  position: relative;
  z-index: 2001;
  width: 56px;
  height: 24px;
  cursor: pointer;
  margin-top: 50px;
}
.togglenavigation:after {
  content: 'Menu';
  position: absolute;
  left: 70px;
  top: 4px;
  font-size: 16px;
  line-height: 1.1875;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  color: var(--s-togglecolor);
  text-decoration: none;
  transition: all 0.4s;
}
.togglenavigation.cb-toggle-active:after {
  opacity: 0;
}
@media (max-width: 1023px) {
  .togglenavigation {
    margin-top: 0;
    width: 30px;
    height: 16px;
  }
  .togglenavigation:after {
    display: none;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 2px;
  background-color: var(--s-togglecolor);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1023px) {
  .tline {
    width: 30px;
    height: 1px;
  }
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  --deskWidth: 1496px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #EDEAE4;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.container--navi {
  float: left;
  width: 100%;
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-top: var(--s-headerHeight);
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navi {
  float: left;
  width: 100%;
}
.mobile-navigation .part--socialLinks {
  margin: 50px 0;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #002E4B;
  text-decoration: none;
}
.mobile-navigation div.sub2 {
  box-sizing: border-box;
  margin-top: 50px;
}
.mobile-navigation div.sub2 > .item {
  box-sizing: border-box;
  padding: 12px 20px;
  border-top: 1px solid #D6D0C4;
}
.mobile-navigation div.sub2 > .item.exit {
  border-bottom: 1px solid #D6D0C4;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 27px;
  line-height: 1.2962963;
  font-family: 'QuicheSansMedium', serif;
  font-weight: 500;
  padding-right: 30px;
  position: relative;
}
.mobile-navigation div.sub2 > .item > .menu.path {
  color: var(--s-linkcolor-hov);
}
.mobile-navigation div.sub3 {
  display: flex;
  flex-direction: column;
  gap: 3px 0;
}
.mobile-navigation div.sub3 > .item.init {
  margin-top: 17px;
}
.mobile-navigation div.sub3 > .item.exit {
  margin-bottom: 8px;
}
.mobile-navigation div.sub3 > .item > .menu {
  font-size: 17px;
  line-height: 1.58823529;
  color: var(--s-textcolor);
}
.mobile-navigation div.sub3 > .item > .menu.path {
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  text-decoration: underline;
}
@media (max-width: 1023px) {
  .mobile-navigation div.sub3 {
    overflow: hidden;
    height: 0;
    transition: all 0.6s;
  }
  .mobile-navigation div.sub3 > .item {
    opacity: 0;
    transform: translateY(-25px);
    transition: all 0.2s;
  }
  .mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
    height: var(--js-elementHeight);
  }
  .mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.4s 0.2s;
  }
}
@media (max-width: 1023px) {
  .mobile-navigation .cb-toggle {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2;
    cursor: pointer;
    width: 23px;
    height: 23px;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/images/navi-toggle.svg);
    transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .mobile-navigation .cb-toggle.cb-toggle-active {
    transform: rotate(-45deg);
  }
}
.home {
  height: 74px;
}
.container--head {
  margin: 20px 0;
}
.headpart {
  justify-content: center;
  width: calc(100% / 3);
}
.headpart--two {
  align-items: center;
}
.section--one,
.section--two,
.section--three,
.section--four,
.section--five,
.section--six,
.section--seven,
.section--eight,
.section--nine {
  padding: calc(100px - var(--spaceTotal)) 0;
}
.layout1 .section--one {
  padding-bottom: 0;
}
.layout1 .section--two {
  padding-top: calc(100px - (var(--spaceTotal) * 2));
}
.eventpart {
  width: 100%;
}
.layout3 .container--mood {
  flex-direction: column;
}
.moodbackground {
  height: 100%;
}
.layout3 .moodbackground {
  max-height: calc(100% - 100px);
}
.layout1 .mood {
  width: 100%;
  height: 470px;
}
.layout2 .mood {
  width: 100%;
  height: 470px;
}
.layout3 .mood {
  width: 100%;
  height: 400px;
}
.layout1 .content {
  max-width: calc(100% - 80px);
  top: 130px;
}
.layout2 .content {
  flex-direction: column;
  padding: 40px var(--deskSpace);
}
.layout3 .content {
  padding: 40px 0;
}
.layout4 .content {
  max-width: 926px;
  padding: 40px 0;
}
.cbdModule--introText {
  margin-top: 40px;
}
.cbdModule--voucherCta {
  right: 22px;
  bottom: 16px;
}
.cbdModule--voucherCta .open {
  width: 88px;
  height: 88px;
  padding: 45px 10px 23px 10px;
  font-size: 13px;
  line-height: 1.07692308;
}
.cbdModule--voucherCta .open:before {
  top: 18px;
  width: 21px;
  height: 21px;
}
.container--newsletter {
  margin: 100px 0;
  flex-direction: column;
}
.newsletter__content {
  width: 100%;
}
.newsletter__link {
  width: 100%;
}
.cbdModule--newsletterText {
  margin-top: calc(var(--spacePart) * 2);
}
.cbdModule--newsletterLink {
  margin-top: calc(var(--spacePart) * 3);
}
.footarea {
  margin: 50px 0 80px;
}
.footunit {
  flex-direction: column;
}
.footunit--one {
  gap: 50px 0;
}
.footunit--two {
  gap: 30px 0;
  margin-top: 50px;
}
.footpart {
  width: 100%;
  text-align: center;
  align-items: center;
}
.foottitle {
  margin-bottom: 20px;
}
.footlogo {
  height: 74px;
}
.part--socialLinks .iconLinks__content {
  justify-content: center;
}
.unit--legal .unit__body {
  justify-content: center;
}
.langnavi--footer .sub1 {
  justify-content: center;
}
.area--one .unitTwo {
  display: flex;
  flex-direction: column;
}
.area--one .unitTwo .unit__content {
  box-sizing: border-box;
}
.area--one .unitTwo--imageLeft .unit__content {
  margin-top: calc(50px - var(--spacePart));
}
.area--one .unitTwo--imageRight .unit__background {
  order: 2;
}
.area--one .unitTwo--imageRight .unit__content {
  order: 1;
  margin-bottom: calc(50px - var(--spacePart));
}
.area--one .unitThree {
  flex-direction: column;
}
.area--one .unitThree .unit__background {
  height: 350px;
}
.area--one .unitThree .unit__background * {
  height: 100%;
}
.area--one .unitThree .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitThree .unit__background .placeholder {
  padding-bottom: 0;
}
.area--one .unitThree .unit__content {
  padding: 50px 20px;
}
.area--one .unitFour {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 350px;
}
.area--one .unitFour .unit__content {
  box-sizing: border-box;
  padding: 400px 20px 50px;
}
.area--one .unitFour .part--imageSlider {
  --spacePart: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 350px;
}
.area--one .unitFour .part--imageSlider *:not(.ns-sliderControl) {
  height: 100% !important;
}
@media (max-width: 1023px) {
  .area--one .unitFive .part + .part {
    margin-top: calc((var(--spacePart) + 1px) * -1);
  }
}
.area--one .unitSix .unit__content {
  padding: var(--spacePart) 20px;
}
.area--one .unitSix .more {
  padding-right: 60px;
}
.area--one .unitSix .more .part:last-child {
  margin-bottom: var(--spacePart);
}
.area--one .unitSix .fold-toggle {
  padding-right: 60px;
}
.area--one .unitSix .fold-toggle:before {
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
}
.area--one .unitSix .fold-toggle:after {
  right: 0;
  top: -10px;
  width: 20px;
  height: 38px;
  margin-left: 10px;
  margin-right: 10px;
}
#view .area--one .unitSix.unit--foldOpen .fold-toggle:after {
  top: 10px;
}
.area--one .unit--form .unit__content {
  padding: 20px;
}
.area--two .unitOne .unit__content {
  position: relative;
  isolation: isolate;
  justify-content: flex-end;
  min-height: 290px;
  padding: calc(20px - var(--spacePart)) 20px;
}
.area--two .unitOne .unit__content:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.47), transparent);
  transition: all 0.4s;
  z-index: -1;
}
#view .area--two .unitOne .unit__content:hover:before,
#view .area--two .unitOne .unit__content:focus:before {
  opacity: 0;
}
.area--three .unitOne {
  padding-left: 20px;
  padding-right: 75px;
}
.area--three .unitOne.unit--isAnchor:before {
  width: 40px;
  height: 40px;
  right: 0;
}
.area--three .unitOne.unit--isAnchor:after {
  width: 38px;
  height: 20px;
  right: 14px;
}
.ns-slider {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider__content {
  float: left;
  width: 100%;
}
.ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider__area {
  float: left;
  width: 100%;
  display: flex;
}
.ns-slide {
  float: left;
  width: 100%;
  flex-shrink: 0;
  transition: all 0.8s;
}
.ns-slide__content {
  float: left;
  width: 100%;
}
.ns-slider__part {
  float: left;
  width: 100%;
}
.ns-slider__part--image {
  aspect-ratio: 1.5;
}
@supports not (aspect-ratio: 3 /  2) {
  .ns-slider__part--image:before {
    float: left;
    padding-top: 66.66666667%;
    content: '';
  }
  .ns-slider__part--image:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-slider__part--image * {
  height: 100%;
}
.ns-slider__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-slider__part--image .placeholder {
  padding-bottom: 0;
}
.ns-sliderControl {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 68px;
  height: 68px;
  transform: translateY(-50%);
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#view .is-noSlider .ns-sliderControl {
  display: none;
}
@media (max-width: 767px) {
  .ns-sliderControl {
    width: 21px;
    height: 21px;
  }
}
.ns-sliderControl--prev {
  left: 24px;
  background-image: url(/images/arrow-left-white.svg);
}
@media (max-width: 767px) {
  .ns-sliderControl--prev {
    left: 20px;
  }
}
.ns-sliderControl--next {
  right: 24px;
  background-image: url(/images/arrow-right-white.svg);
}
@media (max-width: 767px) {
  .ns-sliderControl--next {
    right: 20px;
  }
}
/*# sourceMappingURL=./screen-small.css.map */